import Vue from "vue";
import VueRouter from "vue-router";
const routePC = [
  {
    path: "/",
    name: "Index",
    component: () => import("@/views/Home"),
  },
  {
    path: "/newsList",
    name: "NewsList",
    component: () => import("@/views/News"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/newsDetails",
    name: "NewsDetails",
    component: () => import("@/views/NewsDetails"),
  },
  {
    path: "/visual",
    name: "Visual",
    component: () => import("@/views/visual"),
  },
  {
    path: "/soundtrack",
    name: "Soundtrack",
    component: () => import("@/views/soundtrack"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("@/views/Privacy"),
  },
  {
    path: "/privacy_yq",
    name: "Privacy_yq",
    component: () => import("@/views/Privacy_yq"),
  },
  {
    path: "/protocol",
    name: "Protocol",
    component: () => import("@/views/Protocol"),
  },
  {
    path: "/activity",
    name: "Activity",
    component: () => import("@/views/Activity"),
  },
  {
    path: "/childProtection",
    name: "ChildProtection",
    component: () => import("@/views/ChildProtection"),
  },
  {
    path: "/guardianship",
    name: "Guardianship",
    component: () => import("@/views/Guardianship"),
  },
  {
    path: "/gamePunishment",
    name: "GamePunishment",
    component: () => import("@/views/GamePunishment"),
  },
  {
    path: "/thirdPartySdk",
    name: "ThirdPartySdk",
    component: () => import("@/views/ThirdPartySdk"),
  },
  {
    path: "*",
    redirect: "/",
  },
];
const routePhone = [
  {
    path: "/",
    name: "PHHome",
    component: () => import("@/mobileViews/Home"),
  },
  {
    path: "/activity",
    name: "Activity",
    component: () => import("@/mobileViews/Activity"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("@/mobileViews/Privacy"),
  },
  {
    path: "/protocol",
    name: "Protocol",
    component: () => import("@/mobileViews/Protocol"),
  },
  {
    path: "/childProtection",
    name: "ChildProtection",
    component: () => import("@/mobileViews/ChildProtection"),
  },
  {
    path: "/guardianship",
    name: "Guardianship",
    component: () => import("@/mobileViews/Guardianship"),
  },
  {
    path: "/newsList",
    name: "NewsList",
    component: () => import("@/mobileViews/NewsList"),
  },
  {
    path: "/videoList",
    name: "VideoList",
    component: () => import("@/mobileViews/VideoList"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/newsDetails",
    name: "NewsDetails",
    component: () => import("@/mobileViews/NewsDetails"),
  },
  {
    path: "/gamePunishment",
    name: "GamePunishment",
    component: () => import("@/mobileViews/GamePunishment"),
  },
  {
    path: "/thirdPartySdk",
    name: "ThirdPartySdk",
    component: () => import("@/mobileViews/ThirdPartySdk"),
  },
  {
    path: "*",
    redirect: "/",
  },
];
let routes = []
Vue.use(VueRouter);
if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
  routes = routePhone
} else {
  routes = routePC
}

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) { // 解决vue页面之间跳转，页面不是在顶部的问题
    if (to.path === '/') {
      return { x: 0, y: 0 }
    }
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
